<template>
  <div class="sms_template_list">
    <el-card>
      <nav class="input" style="width: auto">
        <el-select v-model="search.exhibitionId" size="medium" placeholder="请选择企业分类">
          <el-option
              v-for="item in exhibitionList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </nav>
      <nav class="input" style="width: 150px">
        <el-button type="primary" size="small" @click="$refs.table.reload()">{{ "搜索" }}</el-button>
      </nav>
      <nav class="input" style="width: auto">
        <el-button type="primary" size="small" @click="onEdit">新增通知</el-button>
      </nav>
    </el-card>
    <el-card style="margin-top: 10px">
      <z-table ref="table" v-model="list" @query="queryList" fullscreenLoading>
        <el-table :data="list" style="width: 100%" border stripe :header-cell-style="rowClass">
          <el-table-column prop="id" label="ID" width="80px"> </el-table-column>
          <el-table-column prop="content" label="通知内容">
            <template  slot-scope="{ row }">
              <el-tooltip :content="row.content" placement="bottom" >
                <div class="clamp-1">{{row.content}}</div>
              </el-tooltip>

            </template>
          </el-table-column>
          <el-table-column prop="title" label="标题"> </el-table-column>
          <el-table-column prop="file" label="附件"> </el-table-column>
          <el-table-column prop="category" label="通知类型">
            <template slot-scope="{ row }">
              <el-tag type="info" v-if="row.category==1">普通通知</el-tag>
              <el-tag  v-else>展商须知</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="exhibitionName" label="展会"> </el-table-column>
          <el-table-column prop="exhibitionArea" label="展区"> </el-table-column>
          <el-table-column prop="top" label="是否置顶">
            <template slot-scope="{ row }">
              <div>
                <el-tag v-if="row.top" type="success">置顶中</el-tag>
                <el-tag v-else type="info">未置顶</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="{ row }">
              <el-button @click="onEdit(row)" type="text" icon="el-icon-edit" size="mini">编辑</el-button>
              <el-button
                  @click="onDelete(row)"
                  type="text"
                  icon="el-icon-delete"
                  size="mini"
                  style="color: red;"
              >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </z-table>
    </el-card>
    <el-dialog
        title="添加企业"
        :visible.sync="addSms"
        width="30%"
        center>
      <div class="input-detail" >
        <input placeholder="输入企业名称模糊查询" class="clear-input"
               v-model="state"  type="text" name="" id=""
               @input="querySearchAsync" @blur="toClose" />
        <div class="theSearchBox" v-show="searchInput">
          <div class="item flex-align" v-for="(item, index) in searchList" :key="item.id">
            <img :src="item.logo" alt="" />
            <div class="desc clamp-1">{{ item.name }}</div>
            <div class="btn" @click="addEnterprise(item)">添加</div>
          </div>
<!--          <div class="foot">-->
<!--            <div class="add-text">搜索不到您要添加的企业，请联系客服添加</div>-->
<!--            <img src="https://static-cdn.yfchuhai.com/www.yfchuhai.com/static/pc/img/index/grounp/anan_code.jpg" alt="" />-->
<!--          </div>-->
        </div>
      </div>
      <!--      <div class="list flex-sh">-->
      <!--        <div class="list-item flex-center" v-for="(item, index) in enterpriseList" :key="index">-->
      <!--          <img :src="item.logo" alt="" />-->
      <!--          <div class="desc">{{ item.name }}</div>-->
      <!--          <div class="box flex-center">-->
      <!--            <div class="btn" @click="onDel(item)">删除</div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <span slot="footer" class="dialog-footer">-->
      <!--    <el-button @click="addSms = false">取 消</el-button>-->
      <!--    <el-button type="primary" @click="addEnterprise">确 定</el-button>-->
      <!--  </span>-->
    </el-dialog>
  </div>
</template>

<script>
import list from "/src/mixin/list.js";
import {debounce} from "@/utils/methods";
import Img from "@/views/enterprise/enterprise_cert_list/img.vue";
export default {
  components: {Img},
  mixins: [list],
  data() {
    return {
      search: {
        exhibitionId: ""
      },
      addForm:{
        categoryId:"1",
        enterpriseId:""
      },
      addSms:false,
      state:"",
      searchList:[],
      searchInput:false,
      exhibitionList:[],
      enterpriseList:[]
    };
  },
  activated() {
    console.log(1);
  },
  created() {
    this.getExhibitionList()
  },

  methods: {
    async getExhibitionList(){
      let { data: res } = await this.$http.get("/admin/Exhibition/getExhibitionList?page=1&pageSize=100");
      // this.$message.success(res.message);
      if(res.errorCode == 200){
        this.exhibitionList=res.data.list
        this.search.exhibitionId=this.exhibitionList[0].id
        this.getlist()
      }
    },
    querySearchAsync: debounce(async function () {
      this.state=this.state.trim()

      const { data } = await this.$http.get(
          '/api/Enterprise/getList' ,{params:{
              page:'1',
              pageSize:'5',
              type:'-1',
              business:'-1',
              serverArea:'-1',
              keyword:this.state}}
      );
      console.log(data);
      this.searchList = data.data.list;
      this.searchInput = true;
    }),
    toClose() {
      setTimeout(() => {
        this.searchInput = false;
      }, 300);
    },
    async addEnterprise(row){
      let { data: res } = await this.$http.post("/admin/Exhibition/addAccount", {enterpriseId:row.id});
      this.addSms=false
      this.getlist()
    },

    getlist() {
      this.$refs.table.refresh();
      console.log(this.search)
      console.log(this.voteCategoryList)
    },

    async queryList(val) {
      let { data: res } = await this.$http.get("/admin/Exhibition/getNoticeList", {
        params: {...val, ...this.search},
      });
      if (res.errorCode == 200) {
        // this.$message.success(res.message);
        this.$refs.table.complete(res.data);
      }
    },
    onEdit(row) {
      this.$router.push({ name: "exhibition_add_notice", query: { id: row.id } });
    },
    async onDelete(row) {
      await this.$confirm("此操作将永久删除该, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
      let { data: res } = await this.$http.post("/admin/Exhibition/delNotice", {id:row.id});
      if (res.errorCode == 200) {
        this.$message.success(res.message);
        this.getlist();
      }
    },
    Ftag(val) {
      if (val == 0) {
        return "待审核";
      }
      if (val == 1) {
        return "审核通过";
      }
      if (val == 2) {
        return "审核不通过";
      }
      return "";
    },
  },
};
</script>

<style lang="less" scoped>
.logo-img{
  width: 60px;
  height: 60px;
}
.input-detail {
  margin-top: 20px;
  width: 98%;
  height: 38px;
  background: #ffffff;
  border: 1px solid #dcdfe6;
  padding-left: 16px;
  position: relative;
  margin-bottom: 30px;
  z-index: 2;

  input {
    &::placeholder {
      color: #9EA2B5;
    }
  }

  input {
    width: 100%;
    height: 100%;
    border: 0;
    outline: 0;
    background-color: transparent;
    &::-webkit-input-placeholder {
      font-size: 14px;
      font-weight: 500;
      color: #999999;
    }
  }
}
:deep(.el-card .el-card__body){
  padding: 0 30px;
}
.input-tip {
  color: #9EA2B5;
  font-size: 14px;
  margin-top: 17px;
  line-height: 1.5;
  span{
    color: #E2241B;
  }
}
.theSearchBox {
  width: 368px;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  padding: 20px 25px;
  position: absolute;
  top: 47px;
  left: 0px;

  .undata {
    width: 89px;
    height: 34px;
    background: #0a7ff2;
    border-radius: 17px;
    color: #fff;
    line-height: 34px;
    text-align: center;
    cursor: pointer;
  }

  .foot {
    margin-top: 20px;
    text-align: center;

    .add-text {
      color: #333333;
      font-size: 16px;
    }

    img {
      width: 165px;
      height: 165px;
      margin-top: 30px;
    }


  }
  .item {
    // margin-bottom: 30px;
    padding: 15px 0;
    border-bottom: 1px solid rgba(229, 229, 229, 0.6);

    &:first-child {
      padding-top: 0;
    }

    img {
      width: 54px;
      height: 54px;
      border-radius: 10px;
      flex-shrink: 0;
    }

    .desc {
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      margin-left: 22px;
      width: 154px;
    }

    .btn {
      width: 90px;
      height: 35px;
      border: 1px solid #0a7ff2;
      border-radius: 17px;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      color: #0a7ff2;
      line-height: 35px;
      margin-left: auto;
      flex-shrink: 0;
    }
  }
}
.list {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-gap: 10px;
  .list-item {
    flex-direction: column;
    width: 100px;
    height: 100px;
    background: #ffffff;
    border: 1px solid #f2f2f2;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 10px;
    display: flex;

    &:hover {
      .box {
        display: flex;
      }
    }

    >img {
      width: 80px;
      height: 80px;
      border-radius: 6px;
      margin-bottom: 5px;
    }

    .desc {
      width: 90px;
      font-size: 12px;
      font-weight: 400;
      color: #333333;
      overflow: hidden;
      white-space: nowrap;
      text-overflow:ellipsis;
      text-align: center;
    }

    .box {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);

      .btn {
        width: 100px;
        height: 42px;
        line-height: 42px;
        text-align: center;
        background: #ffffff;
        border-radius: 21px;
        color: #0a7ff2;
        font-size: 18px;
      }
    }
  }
}
</style>
